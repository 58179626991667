:root{
  --main-bg-color: #282828;
  --border-color: #ebebeb;
  --secondary-bg-color: #4c4c4c;
  --max-info-width: 127vh;
}

* {
  scrollbar-width: 12px;
  scrollbar-color: var(--secondary-bg-color) var(--main-bg-color);
}

*::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}

*::-webkit-scrollbar-track {
  background: black;
}

*::-webkit-scrollbar-thumb {
  background-color: var(--secondary-bg-color);
  border-radius: 10px;
  border: 1px solid var(--border-color);
}

h1{
  font-size: 1em;
}

a{
  color: var(--border-color);
}

.noselect {
  -webkit-touch-callout: none; 
  -webkit-user-select: none; 
  -khtml-user-select: none; 
  -moz-user-select: none; 
  -ms-user-select: none; 
  user-select: none; 
}

.app {
  display: flex;
  justify-content: left;
  text-shadow: -0.4vh 0.4vh #000000;
  background-color: var(--main-bg-color);
  height: 100vh;
  width: 100vw;
  color: var(--border-color);
  overflow: hidden;
  font-family: "Consolas";
}

.name {
  display: flex;
  width: 5vh;
  height: 100%;
  writing-mode: vertical-lr;
  text-orientation: upright;
  align-items: center;
  justify-content: center;
  font-weight:700;
  font-size: 5vh;
  cursor: pointer;
  margin-left: max(0vw, calc(15vw - 60px));
  margin-right: max(5vw, calc(15vw - 40px));
	background: linear-gradient(75deg, var(--main-bg-color), var(--secondary-bg-color), var(--main-bg-color), var(--secondary-bg-color), var(--main-bg-color), var(--secondary-bg-color), var(--main-bg-color), var(--secondary-bg-color), var(--main-bg-color), var(--secondary-bg-color), var(--main-bg-color), var(--secondary-bg-color), var(--main-bg-color));
  background-size: 400% 400%;

  -webkit-animation: AnimationName 50s ease infinite;
  -moz-animation: AnimationName 50s ease infinite;
  animation: AnimationName 50s ease infinite;

  transition: font-size 0.2s;
}

.name:hover{
  font-size: 5.2vh;
}

.contact-options{
  position: relative;
  display: flex;
  justify-content: space-around;
  width: 100%;
  max-width: 400px;
  height: fit-content;
  top: 10vh;
  
}

.contact-option{
  width: fit-content;
  height: 4vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  font-size: 2vh;
  font-style: inherit;
  font-weight: 500;
  font-family: 'Courier New', Courier, monospace;
  cursor: pointer;
  margin-right: 3vh;
  text-decoration: none;
  transition: all 0.2s;
}

.contact-option:hover{
  font-size: 2.5vh;
}

.contact-icon{
  max-height: 1.4em;
}

.emailimg{
  max-height: 1.2em;
}

.contact{
  background: none;
  border: none;
  color: inherit;
}

.contactform{
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  top:0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 60;
  background-color: var(--main-bg-color);
}

.contactform-form{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: "Consolas";
  width: 80%;
  max-width: 70vh;
}

.contactform-name, .contactform-email, .contactform-message{
  margin: 1vh;
  width: 100%;
}

.contactform-name, .contactform-email{
  width: 70%;
  height: 2em;
}

.contactform-message{
  height: 40vh;
}

.contactform-button{
  background-color: var(--border-color);
  width: fit-content;
  color: var(--main-bg-color);
  border: none;
  margin: 1vh;
  padding: 0.5vh;
  padding-left: 2vh;
  padding-right: 2vh;
  text-align: center;
  cursor: pointer;
  font-size: 1em;
}

.message-hidden{
  visibility: hidden;
}

@-webkit-keyframes AnimationName {
  0%{background-position:7% 0%}
  50%{background-position:94% 100%}
  100%{background-position:7% 0%}
}
@-moz-keyframes AnimationName {
  0%{background-position:7% 0%}
  50%{background-position:94% 100%}
  100%{background-position:7% 0%}
}
@keyframes AnimationName {
  0%{background-position:7% 0%}
  50%{background-position:94% 100%}
  100%{background-position:7% 0%}
}

.info {
  display: block;
  width: calc(100vw - 5vh - calc(max(0vw, calc(15vw - 60px)) + max(5vw, calc(15vw - 30px))));
  height: 100%;
  align-items: center;
  justify-content: space-around;
  overflow-y: scroll;
  overflow-x: hidden;
  font-size: 2.5vh;
  text-shadow: none;
  text-align: left;
}

.description{
  width: 90%;
  max-width: var(--max-info-width);
  padding-top: 16vh;
  /* text-align: justify; */
}

.skills-icons{
  display: flex;
  height: auto;
  width: 88%;
  max-width: calc(var(--max-info-width) - 2vh);
  flex-wrap: wrap;
  margin-top: 5vh;
  border: solid 0.1vh var(--border-color);
  border-radius: 1vh;
  justify-content:space-around;
  background-color: var(--secondary-bg-color);
  padding: 1vh;
}

.techicon-link{
  height: auto;
  margin: 0.5vh;
}

.techicon{
  height: 6vh;
  filter: drop-shadow(-0.2vh 0.2vh 0px var(--border-color));
  
}

.zoomonhover{
  transition: transform .2s; 
}

.zoomonhover:hover{
  transform: scale(1.25);
}

.projectstext{
  font-size: 1.3em;
  font-weight: 600;
  margin-top: 1.5em;
  margin-bottom: 2em;
}

.project{
  font-size: 3vh;
  position: relative;
}

.popup{
  position: absolute;
  background-color: var(--main-bg-color);
  height: 100vh;
  width: 100vw;
  top: 0;
  left: 0;
  visibility: hidden;
  z-index: 50;
  background-repeat: no-repeat;
  background-size: contain;
  background-color: var(--main-bg-color);
  background-position: center center;
  background-image: url('./Components/Images/BZmobileV.webp');
  display: flex;
  justify-content: flex-end;
  
}

.clicktoexit{
  font-size: min(2vw, 2vh);
  background-color: black;
  margin-right: 20%;
  width: 10em;
  height: 4em;
  text-align: center;
  text-justify: center;
  line-height: 4em;
}

.github-img{
  height: 3vh;
  margin-left: 20px;
  transform: translate(0, 0.5vh);
}

.external-link-img{
  height: 1.5vh;
  padding-left: 0.5vh;
}

.project-title{
  height: 4vh;
  transition: font-size 0.2s;
}

.project-title:hover{
  font-size: 105%;
}

.project-description{
  width: 90%;
  max-width: var(--max-info-width);
  font-size: 2.5vh;
}

.project-images{
  display: flex;
  width: auto;
  max-width: 85%;
  overflow-y: hidden;
  padding-bottom: 5vh;
  padding-top: 5vh;
  padding-left: 1.5vh;
}

.project-image{
  height: 25vh;
  margin-right: 3vh;
}


@media (orientation: landscape) and (min-width: 1000px){ /*desktop*/
  .project-images{
    /* max-width: 10000px; */
    width: 100%;
    /* overflow: hidden; */
  }

  .project-image{
    height: 12.5vw;
    max-height: 25vh;
  }

}

@media (orientation: landscape){ /*any landscape*/
  .contact-options{
    width: fit-content;
    justify-content: left;
  }
  .contact-option{
    flex-direction: row;
    justify-content: left;
  }

  .contact-icon{
    margin-right: 1vh;
  }
}

@media (orientation: landscape) and (hover: none){ /*mobile landscape */
  .contact-option{
    font-size: 2vw;
  }

  .info{
    font-size: 2vw;
  }

  .projectstext{
    font-size: 2.5vw;
  }
  .project-title{
    font-size: 2.2vw;
  }

  .project-description{
    font-size: 2vw;
  }

  .external-link-img{
    height: 1.5vw;
  }

  .github-img{
    height: 1.75vw;
    margin-left: 2.5vw;
  }
  .contactform-name, .contactform-email, .contactform-message{
    font-size: 3vh;
  }
}

@media (orientation: portrait) and (hover: none){ /*mobile portrait  */
  .name{
    margin-left: 0;
    margin-right: 5vw;
  }

  .info{
    width: 100%;
  }

  .contact-options{
    height: 5vw;
    width: auto;
    margin-left: 5vw;
    max-width: none;
  }

  .contact-option{
    height: 5vw;
    width: 10vw;
  }

  .contactform-name, .contactform-email, .contactform-message{
    font-size: 3vw;
  }
  
  
}   